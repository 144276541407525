<template>
    <section class="content">
        <div class="row mb-2">
          <div class="col-md-12">
            <div class="info-box mb-3">
              <div class="info-box-content">
                <div class="row">
                  <div class="col-md-12">
                      <div class="text-muted mb-1 font-13 text-uppercase flex">
                          JENIS
                      </div>
                      <h4 class="mb-1">
                      <span> {{ dataSoal.tipe }} </span>
                      </h4>
                  </div>
                  <div class="col-md-12">
                      <div class="text-muted mb-1 font-13 text-uppercase flex">
                          LAMA PENGERJAAN PER KOLOM 
                      </div>
                      <h4 class="mb-1">
                      <span> {{ dataSoal.total_duration }} </span>
                      </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-info">
            <div class="card-header">
                <div class="box-tools card-tools toolbar">
                    <div class="btn-group">
                        <button type="button" @click="downloadPdf()" class="btn btn-success" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-download"></i> Download PDF
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="w-100 d-flex flex-row py-4 px-3" id="content-download" style="overflow-x: scroll; background-color: #F1F9FE;">
                    <div class="bg-white mx-2" style="width: 260px;" v-for="item in soal_main" :id="'col_index_'+item.col_index">
                        
                        <table class="table table-bordered text-center">
                            <tr>
                                <th colspan="5">Kolom {{ item.col_index }}</th>
                            </tr>
                            <tr>
                                <td>A</td>
                                <td>B</td>
                                <td>C</td>
                                <td>D</td>
                                <td>E</td>
                            </tr>
                            <tr>
                                <td v-html="item.data_a"></td>
                                <td v-html="item.data_b"></td>
                                <td v-html="item.data_c"></td>
                                <td v-html="item.data_d"></td>
                                <td v-html="item.data_e"></td>
                            </tr>
                        </table>

                        <table class="table table-bordered text-center">
                            <tr v-for="items in item.rows">
                                <td>{{ items.nomor }}</td>
                                <td colspan="3" style="width: 60%;" v-html="items.soal"></td>
                                <td>{{ items.jawaban }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <router-link :to="{ path: '/list-ujian' }" class="btn btn-secondary mt-3">
                    Kembali
                </router-link>
            </div>
        </div>
    </section>
    <!-- /.content -->
  </template>
  
  <script>
  import { createTable, authFetch } from "@/libs/hxcore";
  import Swal from "sweetalert2";
  import $ from "jquery";
  import vSelect from 'vue-select';
  import "vue-select/dist/vue-select.css";
  import Daterangepicker from 'daterangepicker';
  import "daterangepicker/daterangepicker.css";
  import moment from "moment";
  import jsPDF from 'jspdf';
  import html2canvas from 'html2canvas';
  
  export default {
    name: "",
    data() {
      return {
        dataSoal: {},
        soal_main: [],
        errors: [],
        optMapel: [],
        method: "",
        roles: "",
        ujian_id: "",
        formTitle: "Tambah",
        form: {
          tipe: "",
          jumlah_kolom: "",
          jumlah_baris: "",
          jumlah_menit: "",
          ujian_id: this.$route.params.id,
        },
      };
    },
    created: function () {
      this.roles = this.$route.meta.roles;
      console.log("load initial data", this.$route);
      this.ujian_id = this.$route.params.id;
  
      this.loadDetail(this.$route.params.id);
    },
    components: { 
      vSelect, 
    },
    methods: {
      navigateToDetail(event) {
        console.log(event)
        this.$router.push("/detail-ujian/psikotes-detail/" + event);
      },
      loadDetail: function(id){
        authFetch("/akademik/ujian/data_soal_psikologi/" + id).then((res) => {
          res.json().then((json) => {
            this.dataSoal = json.tipe;
            this.soal_main = json.col
                console.log('joss',this.soal_main);
          });
        });
      },
      submitForm: function (ev) {
        const e = this.$refs;
  
        var data = Object.keys(this.form)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
          )
          .join("&");
        var urlSubmit = "/akademik/ujian/generate_psikologi";
        if (this.method == "PUT")
          urlSubmit = "/akademik/ujian/generate_psikologi/" + this.form.id;
  
        authFetch(urlSubmit, {
          method: "POST",
          body: data,
        })
          .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
          })
          .then((js) => {
            this.errors = [];
            if (!js.success) {
              console.log(js.details);
  
              for (var key in js.details) {
                if (js.details.hasOwnProperty(key)) {
                  this.errors.push(js.details[key]);
                }
              }
  
              return;
            }
            this.table.api().ajax.reload();
            $(e.formDialog).modal("hide");
          });
  
        ev.preventDefault();
      },
      handleClick(e) {
        if (e.target.matches(".link-role")) {
          this.$router.push({ path: "/permit/" + e.target.dataset.id });
          return false;
        }
  
        if (e.target.closest("button")) {
            if (e.target.dataset.action == "edit") {
              let id = e.target.dataset.id;
              this.$router.push({ path: `/soal/edit/${id}`, params: {ujian_id: this.$route.params.id}});
            } else if (e.target.dataset.action == "hapus") {
                Swal.fire({
                title: "Hapus soal?",
                icon: "question",
                denyButtonText: '<i class="fa fa-times"></i> Hapus',
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
              }).then((result) => {
                if (result.isDenied) {
                  authFetch("/akademik/ujian/soal/" + e.target.dataset.id, {
                    method: "DELETE",
                    body: "id=" + e.target.dataset.id,
                  })
                    .then((res) => {
                      return res.json();
                    })
                    .then((js) => {
                      this.table.api().ajax.reload();
                    });
                }
              });
            }
        }
      },
      downloadPdf(){
        // var doc = new jsPDF('l', 'mm', [1000,1000]);
        // var pdfjs = document.querySelector('#content-download');

        // // Convert HTML to PDF in JavaScript
        // doc.html(pdfjs, {
        //   callback: function(doc) {
        //     doc.save("output.pdf");
        //   },
        //   x: 10,
        //   y: 10
        // });
        var tipe_soal = this.dataSoal.tipe
        const element = document.getElementById('content-download');
        html2canvas(element).then(function(canvas) {
          const imgData = canvas.toDataURL('image/png');
          var i = new Image();

          i.onload = function(){
            // alert(i.width + ", " + i.height);
            const pdf = new jsPDF({
              // orientation: 'landscape',
              format: [i.width, i.height]
            });
            // pdf.text('Soal Kecermatan tipe: '+tipe_soal, 10, 10);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (i.height * pdfWidth) / i.width;
            console.log(pdfWidth, pdfHeight)
            pdf.addImage({
              imageData : imgData,
              x : 0,
              y : 0,
              width : pdf.internal.pageSize.getWidth(),
              height : pdf.internal.pageSize.getHeight()
            });
            pdf.save("Soal_Kecermatan_"+tipe_soal+".pdf");
          };
          i.src = imgData;
        });
      }
    },
    mounted() {
        const e = this.$refs;
        let self = this;
        new Daterangepicker(this.$refs.daterange, {
          startDate: moment(),
          endDate: moment().day(2),
          }, function(d1, d2) {
              self.form.tanggal_mulai = d1.format('DD/MM/YYYY')
              self.form.tanggal_selesai = d2.format('DD/MM/YYYY')
          }
        );
    },
  };
  </script>
  